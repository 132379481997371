.iframe{

    & .iframe-content{
        width: 80%;
        margin: 0 auto;

        & iframe{
            display: block;

            -webkit-box-shadow: 0px 0px 46px -6px rgba(0,0,0,0.45);
            -moz-box-shadow: 0px 0px 46px -6px rgba(0,0,0,0.45);
            box-shadow: 0px 0px 46px -6px rgba(0,0,0,0.45);
        }
    }

    transition: 500ms ease;
}