.portofolio{

   -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
   -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
   box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);

   padding-top: 40px;
   padding-bottom: 100px;

   & h2{
       text-align: center;
   }

    & .projects{
        width: 80%;
        margin: 0 auto;
        display: flex;
    }


}