@import url(https://fonts.googleapis.com/css?family=Montserrat+Alternates|Bellota|Bellota+Text|Quicksand&display=swap);
@font-face {
    font-family: PoiretOne;
    src: url(/static/media/PoiretOne-Regular.c57e537a.ttf);
  }

html, 
body{
    margin: 0;
    padding: 0;
}

/* html{
    background-color: black;
    color:rgb(192, 192, 192);
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.homepage{background-color:black;color:silver;height:100vh;display:grid;grid-template-columns:1fr 1fr}.homepage-image img{padding-top:10vh;display:block;height:450px;margin:0 auto;border-radius:50%;display:block}.homepage-text{padding-top:25vh;font-family:'PoiretOne', sans-serif}.homepage-text h1{font-size:80px;font-weight:100;margin:0 0 70px 0}.homepage-text .bold{font-weight:900;font-size:78px}.homepage-text h2{margin-left:140px;font-size:1.8em}.homepage-text a{text-decoration:none;color:silver;font-size:30px;border:1px silver solid;padding:10px 20px;font-weight:bold;margin-left:140px}.homepage-text a:hover{background-color:silver;color:black}.homepage-text footer{text-align:center;color:thistle}@media only screen and (max-width: 915px){h1{font-size:65px;text-align:center}.bold{font-size:63px}.under-construction{margin-left:0px}}@media only screen and (max-width: 600px){.wrapper{grid-template-columns:1fr;padding-top:3vh}.under-construction{display:block;margin:0 auto;padding-bottom:5vh}}

.about{min-width:960px;font-family:'Montserrat Alternates', cursive;font-size:1.4rem}

.designer-coder{border-bottom:2px #a8a8a8 solid;background-color:#fafafa;display:grid;grid-template-columns:1fr 1fr 1fr;padding:30px 0;box-shadow:0px 0px 25px 0px rgba(0,0,0,0.75)}.designer-coder .coder{text-align:right;margin-top:40px}.designer-coder .designer{margin-top:100px}.designer-coder .img img{width:390px;display:block;margin:0 auto}

.skills{padding-bottom:50px}.skills .skills-title{text-align:center;padding:60px 0 0 0}.skills .progress-line{margin:0 auto;width:1200px}.skills .logo{margin:0 auto;width:1200px;justify-items:center;display:grid;grid-template-columns:1fr 1fr 1fr}

.progress{background-color:#d8d8d8;border-radius:20px;position:relative;margin:45px auto;height:32px;width:80%}.progress-done{border-radius:20px;color:#fff;display:flex;align-items:center;justify-content:center;height:100%;width:0;opacity:0;transition:1s ease 0.3s}

.portofolio{box-shadow:0px 0px 25px 0px rgba(0,0,0,0.75);padding-top:40px;padding-bottom:100px}.portofolio h2{text-align:center}.portofolio .projects{width:80%;margin:0 auto;display:flex}

.iframe{transition:500ms ease}.iframe .iframe-content{width:80%;margin:0 auto}.iframe .iframe-content iframe{display:block;box-shadow:0px 0px 46px -6px rgba(0,0,0,0.45)}

.custom-button{position:relative;background-color:#d3d3d3;transition-duration:500ms;border-radius:5px;padding:10px 20px;margin-right:10px;box-shadow:0px 33px 57px -32px rgba(0,0,0,0.75)}.custom-button:hover{transform:translateY(-5px);transition-duration:500ms;cursor:pointer;background-color:#b1b1b1;box-shadow:21px 145px 57px -32px rgba(0,0,0,0.75)}.active{background-color:#fc6d6d}.active:hover{transform:translateY(0);background-color:#fc6d6d;cursor:default}

.project-details{width:80%;margin:0 auto;padding-left:50px}.project-details .sourcecode2{margin-left:150px}

