// .progress-bar{
//     border:1px solid black;
// }

.progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	margin: 45px auto;
	height: 32px;
    width: 80%;
}

.progress-done {
	// box-shadow: 0 3px 3px -5px #f06529, 0 2px 5px #f06529;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}