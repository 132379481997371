.designer-coder{
    border-bottom: 2px rgb(168, 168, 168) solid;
    background-color: rgb(250, 250, 250);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 30px 0;

    -webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.75);

    .coder{
        text-align: right;
        margin-top: 40px;
    }

    .designer{
        margin-top: 100px;
    }

    .img{
        img{
            width: 390px;
            display: block;
            margin: 0 auto;
        }
    }
}