// @font-face {
//     font-family: PoiretOne;
//     src: url(../../assets/fonts/PoiretOne-Regular.ttf);
//   }

// /* 
// google fonts:
// Poiret One --> nume
// */

.homepage{
    background-color: black;
    color:rgb(192, 192, 192);
    height: 100vh;
    display: grid;
    grid-template-columns:1fr 1fr;
}


.homepage-image img{
    padding-top: 10vh;
    display: block;
    height: 450px;
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}

.homepage-text{
    padding-top: 25vh;
    font-family: 'PoiretOne', sans-serif; 


    h1{
        font-size: 80px;
        font-weight: 100;
        margin: 0 0 70px 0;
    }

    .bold{
        font-weight: 900;
        font-size: 78px;
    }

    h2{
       margin-left: 140px;
       font-size: 1.8em;
    }

    
    a{
        text-decoration: none;
        color:rgb(192, 192, 192);
        font-size: 30px;
        border: 1px rgb(192, 192, 192) solid;
        padding: 10px 20px;
        font-weight: bold;

        margin-left: 140px;

        &:hover{
            background-color: rgb(192, 192, 192);
            color: black;
        }
    }

    footer{
        text-align: center;
        color: thistle;
    }
}

@media only screen and (max-width: 915px) {
    h1{
        font-size: 65px;
        text-align: center;
    }

    .bold{
        font-size: 63px;
    }
    
    .under-construction{
        margin-left: 0px;
    }
}

@media only screen and (max-width: 600px) {
    .wrapper{
        grid-template-columns:1fr;
        padding-top: 3vh;
    }    

    .under-construction{
        display: block;
        margin: 0 auto;
        padding-bottom: 5vh;
    }
}