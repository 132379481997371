.skills{
    padding-bottom: 50px;

    
    & .skills-title{
        text-align: center;
        padding: 60px 0 0 0 ;
    }

    .progress-line{
        margin:0 auto;
        width: 1200px;
    }

    & .logo{
        margin:0 auto;
        width: 1200px;
        justify-items: center;
        display: grid;
        grid-template-columns:1fr 1fr 1fr;
    }
   
}