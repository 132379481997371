.custom-button{
    position: relative;

    background-color: rgb(211, 211, 211);
    transition-duration: 500ms;

    // border:1px solid black;
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;

    -webkit-box-shadow: 0px 33px 57px -32px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 33px 57px -32px rgba(0,0,0,0.75);
    box-shadow: 0px 33px 57px -32px rgba(0,0,0,0.75);

}

.custom-button:hover{
    transform: translateY(-5px);
    transition-duration: 500ms;
    cursor: pointer;
    background-color: rgb(177, 177, 177);


    -webkit-box-shadow: 21px 145px 57px -32px rgba(0,0,0,0.75);
    -moz-box-shadow: 21px 145px 57px -32px rgba(0,0,0,0.75);
    box-shadow: 21px 145px 57px -32px rgba(0,0,0,0.75);
}

.active{
    background-color: rgb(252, 109, 109);
}

.active:hover{
    transform: translateY(0);
    background-color: rgb(252, 109, 109);
    cursor: default;
}