@font-face {
    font-family: PoiretOne;
    src: url(./assets/fonts/PoiretOne-Regular.ttf);
  }

html, 
body{
    margin: 0;
    padding: 0;
}

/* html{
    background-color: black;
    color:rgb(192, 192, 192);
} */